import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  CellEditRequestEvent,
  ColGroupDef,
  ColumnResizedEvent,
  ICellRendererParams,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useDataTableContext } from "../store/DataTableProvider";
import { ColumnHeaderBase } from "../column-headers/ColumnHeaderBase";
import { CellRendererRowIndex } from "../cell-renderers/CellRendererRowIndex";
import { CellRendererBaseMemo } from "../cell-renderers/CellRendererBase";
import { useCallback, useMemo } from "react";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { CellEditorBase } from "../cell-editors/CellEditorBase";
import { TaskStatus } from "types/constants";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { ShortTaskId } from "components/ShortTaskId/ShortTaskId";
import { formatDate } from "utils/formatDate";
import { CellRendererRowControlsMemo } from "../cell-renderers/CellRendererRowControls";

export type DataTableRowData = {
  id: string;
  editable: boolean;
  task: {
    id: string;
    status: TaskStatus;
    date_created: string;
    credits: number | null;
    duration: string;
  } | null;
  cells: {
    formula: string | null;
    value: CellValue;
  }[];
};

const colDefRowIndex: ColDef<DataTableRowData> = {
  cellRenderer: CellRendererRowIndex,
  cellStyle: {
    display: "flex",
    alignItems: "center",
  },
  colId: "rowNumber",
  headerName: "",
  pinned: "left",
  resizable: false,
  width: 78,
  lockPosition: true,
  suppressMenu: true,
  checkboxSelection: true,
  headerCheckboxSelection: true,
};

const colGroupDefTask: ColGroupDef<DataTableRowData> = {
  groupId: "task",
  headerName: "Task",
  children: [
    {
      colId: "task_status",
      headerName: "Status",
      pinned: "right",
      suppressMovable: true,
      width: 120,
      cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
        const taskStatus = params.data?.task?.status;

        if (taskStatus === undefined) {
          return null;
        }

        return <TaskStatusBadge taskStatus={taskStatus} />;
      },
    },
    {
      colId: "task_log",
      headerName: "Log",
      pinned: "right",
      suppressMovable: true,
      width: 50,
      cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
        const task = params.data?.task;

        if (isNullish(task) || task.status === null) {
          return null;
        }

        return <ButtonViewTaskLogs task={task} />;
      },
    },
    {
      colId: "task_id",
      headerName: "ID",
      pinned: "right",
      suppressMovable: true,
      width: 100,
      columnGroupShow: "open",
      cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
        const task = params.data?.task;

        if (isNullish(task)) {
          return null;
        }

        return <ShortTaskId taskId={task.id} />;
      },
    },
    {
      colId: "task_date_started",
      headerName: "Date Started",
      pinned: "right",
      suppressMovable: true,
      width: 200,
      columnGroupShow: "open",
      cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
        const task = params.data?.task;

        if (isNullish(task)) {
          return null;
        }

        return <span>{formatDate(task.date_created)}</span>;
      },
    },
    {
      colId: "task_duration",
      headerName: "Duration",
      pinned: "right",
      suppressMovable: true,
      width: 120,
      columnGroupShow: "open",
      cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
        const taskDuration = params.data?.task?.duration;

        if (isNullish(taskDuration)) {
          return null;
        }

        return <span>{taskDuration}s</span>;
      },
    },
    {
      colId: "task_compute_credits",
      headerName: "Compute Credits",
      pinned: "right",
      suppressMovable: true,
      width: 150,
      columnGroupShow: "open",
      valueGetter: (params) => params.data?.task?.credits,
    },
  ] satisfies ColDef<DataTableRowData>[],
};

const colDefRowActions: ColDef<DataTableRowData> = {
  colId: "row_controls",
  cellRenderer: (params: ICellRendererParams<DataTableRowData>) => {
    if (params.data === undefined) {
      return null;
    }

    return <CellRendererRowControlsMemo rowId={params.data.id} />;
  },
  headerName: "",
  pinned: "right",
  resizable: false,
  width: 80,
  suppressMovable: true,
  suppressMenu: true,
};

/**
 * Component that renders columns, rows and cells for data tables and analysis
 * tables
 */
const DataTableInner = () => {
  const selectedTable = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    assert(isDefined(table));
    return table;
  });
  const setSelectedRowIds = useDataTableContext((s) => s.setSelectedRowIds);
  const setCellFormula = useDataTableContext((s) => s.setCellFormula);
  const resizeColumn = useDataTableContext((s) => s.resizeColumn);

  const customColDefs: (
    | ColDef<DataTableRowData>
    | ColGroupDef<DataTableRowData>
  )[] = useMemo(() => {
    // Initialize column group definitions
    const groupDefs: ColGroupDef<DataTableRowData>[] =
      selectedTable.columnGroups.map((columnGroup) => ({
        groupId: columnGroup.id,
        headerName: columnGroup.name,
        children: [],
      }));

    // Accumulator holding all column and column group definitions
    const allDefinitions: (
      | ColDef<DataTableRowData>
      | ColGroupDef<DataTableRowData>
    )[] = [];

    // Create column definitions
    selectedTable.columns.forEach((column, index) => {
      const colDef: ColDef<DataTableRowData> = {
        cellEditor: CellEditorBase,
        cellEditorParams: {
          index,
          columnId: column.id,
          tableKind: selectedTable.kind,
        },
        cellRenderer: (
          params: ICellRendererParams<DataTableRowData, CellValue>,
        ) => (
          <CellRendererBaseMemo
            eGridCell={params.eGridCell}
            tableId={selectedTable.id}
            columnId={column.id}
            rowId={params.data?.id ?? ""}
            value={params.value}
            tableKind={selectedTable.kind}
          />
        ),
        cellStyle: { padding: 0 },
        colId: column.id,
        headerComponent: ColumnHeaderBase,
        headerComponentParams: {
          index,
          isColumnDeletable: column.deletable,
          tableId: selectedTable.id,
          tableKind: selectedTable.kind,
        },
        headerName: column.name,
        valueGetter: (params) => params.data?.cells[index].value,
        editable: ({ data }) => column.editable && (data?.editable ?? false),
        resizable: true,
        suppressMovable: true,
        width: column.width,
      };

      // If the column belongs to a group, add it to the group definition.
      if (column.group !== null) {
        const group = groupDefs.find((group) => group.groupId === column.group);
        assert(isDefined(group));
        group.children.push(colDef);
        if (group.children.length === 1) {
          allDefinitions.push(group);
        }
      } else {
        allDefinitions.push(colDef);
      }
    });

    return allDefinitions;
  }, [
    selectedTable.columnGroups,
    selectedTable.columns,
    selectedTable.id,
    selectedTable.kind,
  ]);

  const columnDefs = useMemo(() => {
    // FIXME: For some reason, AG Grid does not like the CellValue type
    // provided by Hyperformula. This produces only produces a type warning
    // so casting is safe but this should be investigated further.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    const defs = [colDefRowIndex, ...(customColDefs as any[])];

    if (selectedTable.kind === "analysis") {
      defs.push(colGroupDefTask, colDefRowActions);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return defs;
  }, [customColDefs, selectedTable.kind]);

  const rowData = useMemo(() => {
    return selectedTable.rows.map((row) => ({
      id: row.id,
      editable: row.editable,
      cells: row.cells,
      task: row.task,
    }));
  }, [selectedTable.rows]);

  const handleCellEditRequest = useCallback(
    (e: CellEditRequestEvent<DataTableRowData>) => {
      const tableId = selectedTable.id;
      const columnId = e.column.getColId();
      const colIndex = selectedTable.columns.findIndex(
        ({ id }) => id === columnId,
      );
      const rowId = e.data.id;
      const oldFormula = e.data.cells[colIndex].formula;
      const newFormula = String(e.newValue);

      if (oldFormula !== newFormula) {
        void setCellFormula({ tableId, columnId, rowId }, newFormula);
      }
    },
    [selectedTable.columns, selectedTable.id, setCellFormula],
  );

  const handleSelectionChanged = useCallback(
    (e: SelectionChangedEvent<DataTableRowData>) => {
      const selectedRowIds = e.api.getSelectedRows().map((row) => row.id);
      setSelectedRowIds(selectedRowIds);
    },
    [setSelectedRowIds],
  );

  const handleColumnResized = useCallback(
    (e: ColumnResizedEvent<DataTableRowData>) => {
      const isColumnStillDragging = !e.finished;
      if (isColumnStillDragging || e.source !== "uiColumnResized") {
        return;
      }

      if (isNonNull(e.column)) {
        void resizeColumn({
          tableId: selectedTable.id,
          columnId: e.column.getColId(),
          newWidth: e.column.getActualWidth(),
        });
      }
    },
    [resizeColumn, selectedTable.id],
  );

  return (
    <AgGridReact<DataTableRowData>
      className="ag-theme-balham-cell-borders"
      columnDefs={columnDefs}
      getRowId={({ data }) => data.id}
      rowData={rowData}
      rowHeight={35}
      readOnlyEdit
      onCellEditRequest={handleCellEditRequest}
      rowSelection="multiple"
      suppressRowClickSelection
      suppressRowHoverHighlight
      onSelectionChanged={handleSelectionChanged}
      onColumnResized={handleColumnResized}
    />
  );
};

export const DataTable = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  return isDefined(selectedTableId) ? (
    <DataTableInner key={selectedTableId} />
  ) : (
    <AgGridReact
      key="empty"
      className="ag-theme-balham-cell-borders"
      rowData={[]}
    />
  );
};
