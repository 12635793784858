import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiSpacer,
} from "@inscopix/ideas-eui";
import { useEffect, useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldColumn } from "../../fields/FieldColumn";
import { FieldFileMetadatum } from "../../fields/FieldFileMetadatum";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { getColumnAddress } from "pages/gdt/column-headers/ColumnHeaderBase";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
  };
  file_column: {
    value:
      | { id: string; order: number; table: { id: string; key: string } }
      | undefined;
    isValid: boolean;
  };

  metadatum_key: {
    value: string | undefined;
    isValid: boolean;
  };
};

interface PanelCreateColumnFileMetadatumProps {
  onClose: () => void;
}

export const PanelCreateColumnFileMetadatum = ({
  onClose,
}: PanelCreateColumnFileMetadatumProps) => {
  const createColumn = useDataTableContext((s) => s.createColumn);
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState<Fields>({
    name: {
      value: "",
      isValid: false,
    },
    file_column: {
      value: undefined,
      isValid: false,
    },
    metadatum_key: {
      value: undefined,
      isValid: false,
    },
  });
  const fileType = useDataTableContext((s) => {
    const allColumns = s.tables.flatMap((table) => table.columns);
    const fileColumn = allColumns.find(
      (column) => column.id === fields.file_column.value?.id,
    );
    return fileColumn?.definition.kind === "file"
      ? fileColumn.definition.file_type
      : undefined;
  });

  const handleFieldChange = <T extends keyof Fields>(
    fieldName: T,
    field: Fields[T],
  ) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: field,
    }));
  };

  const handleSubmit = async () => {
    const fileColumn = fields.file_column.value;
    const metadatumKey = fields.metadatum_key.value;
    assert(isDefined(fileColumn) && isDefined(metadatumKey));

    const fileColumnAddress = `${fileColumn.table.key}!${getColumnAddress(
      fileColumn.order,
    )}:${getColumnAddress(fileColumn.order)}`;

    setIsLoading(true);
    const { error } = await createColumn({
      tableId: selectedTableId ?? "",
      name: fields.name.value,
      defaultFormula: `=METADATUM(${fileColumnAddress}, "${metadatumKey}")`,
      definition: {
        kind: "metadatum",
        file_column: fileColumn.id,
        metadatum_key: metadatumKey,
      },
      editable: false,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column created");
    } else {
      addUtilityToastFailure("Failed to create column");
    }

    onClose();
  };

  // Reset metadatum selection when file type changes
  useEffect(() => {
    handleFieldChange("metadatum_key", {
      value: undefined,
      isValid: false,
    });
  }, [fileType]);

  return (
    <EuiForm style={{ padding: 16 }}>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => handleFieldChange("name", field)}
      />

      <FieldColumn
        label="File column"
        helpText="Choose a file column to provide the value for this metadatum column."
        filter={{ columnKind: "file", tableId: selectedTableId }}
        value={fields.file_column.value}
        onChange={(field) => handleFieldChange("file_column", field)}
      />

      <FieldFileMetadatum
        disabled={fileType === undefined}
        fileType={fileType}
        value={fields.metadatum_key.value}
        onChange={(field) => handleFieldChange("metadatum_key", field)}
      />

      <EuiSpacer />

      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiButton
            isLoading={isLoading}
            fill
            onClick={() => void handleSubmit()}
            disabled={Object.values(fields).some((field) => !field.isValid)}
          >
            Insert
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  );
};
