import {
  CellValue,
  DetailedCellError,
  Group,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { DataTableStoreState } from "../store/DataTableProvider.types";
import { TaskStatus } from "types/constants";

export type Alpha = boolean | string | number | string[] | null | ShapeJson[];

export const convertCellValueToToolInput = (value: CellValue): Alpha => {
  if (value instanceof DetailedCellError || value === null) {
    return null;
  }

  if (
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "string"
  ) {
    return value;
  }

  if (value instanceof IdeasFile) {
    return value.attrs.id;
  }

  if (value instanceof RoiFrame) {
    return value.shapes;
  }

  if (value instanceof RowIdentifier) {
    return null;
  }

  // FIXME: Convert to array
  if (value instanceof Group) {
    return [];
  }

  throw new Error("Unsupported cell value type");
};

export const convertCellValueToStaticFormula = (
  value: CellValue,
): string | null => {
  if (value instanceof DetailedCellError || value === null) {
    return null;
  }

  if (typeof value === "boolean") {
    return value === true ? "=TRUE()" : "=FALSE()";
  }

  if (typeof value === "number" || typeof value === "string") {
    return value.toString();
  }

  if (value instanceof IdeasFile) {
    const fileId = value.attrs.id;
    return `=FILE("${fileId}")`;
  }

  if (value instanceof RoiFrame) {
    const serializedShapes = JSON.stringify(value.shapes);
    return `=ROI_FRAME("'${serializedShapes}")`;
  }

  if (value instanceof RowIdentifier) {
    const { tableKey, rowIndex } = value;
    return `=ROW_IDENTIFIER("${tableKey}",${rowIndex})`;
  }

  // FIXME: Convert to array
  if (value instanceof Group) {
    return null;
  }

  throw new Error("Unsupported cell value type");
};

export const isAnalysisTableRowExecuting = (
  row: DataTableStoreState["tables"][number]["rows"][number],
) => {
  return (
    row.task !== null &&
    ![
      TaskStatus.CANCELED,
      TaskStatus.COMPLETE,
      TaskStatus.ERROR,
      TaskStatus.FAILED,
    ].includes(row.task.status)
  );
};
