/** @jsxImportSource @emotion/react */
import {
  EuiBadge,
  EuiButton,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
} from "@inscopix/ideas-eui";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { ToolSpec } from "../ToolParamsGrid/ToolParamsGrid.types";
import { Maybe } from "graphql/_Types";
import { isNonNull } from "utils/isNonNull";
import moment from "moment";
import { css } from "@emotion/react";

const TABLE_KEY_ILLEGAL_CHAR_REGEX = /[^A-Z0-9_]/g;
const TABLE_KEY_MAX_LENGTH = 10;

const styles = {
  detailList: css`
    min-height: 0;
    overflow-y: scroll;
    flex: 1 1 0;
    padding: 8px;
  `,
  tableForm: css`
    padding: 8px;
    background-color: white;
    border-top: 1px solid #e5e5e5;
  `,
};

interface WorkflowSelectionModalDetailProps {
  selectedToolSpecKey: string | undefined;
  toolSpecs: (Pick<
    ToolSpec,
    "key" | "name" | "help" | "version" | "toolkit" | "maturity"
  > & {
    credits: Maybe<number>;
    toolCreated: string;
    versionCreated: string;
    toolId: number;
  })[];
  isLoading: boolean;
  handleSubmit: (
    e: MouseEvent<HTMLButtonElement>,
    tableName: string,
    tableKey: string,
  ) => void;
}

export const WorkflowSelectionModalDetail = ({
  selectedToolSpecKey,
  toolSpecs,
  isLoading,
  handleSubmit,
}: WorkflowSelectionModalDetailProps) => {
  // const { analysisTableGroups } = useProjectDataContext();
  const selectedToolSpec = toolSpecs.find(
    (spec) => spec.key === selectedToolSpecKey,
  );
  const [tableName, setTableName] = useState<string>("");
  const [tableKey, setTableKey] = useState<string>("");
  const onChangeName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTableName(e.target.value);
  };
  const onChangeKey = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newKey = e.target.value
      .toUpperCase()
      .replace(TABLE_KEY_ILLEGAL_CHAR_REGEX, "")
      .substring(0, TABLE_KEY_MAX_LENGTH);
    setTableKey(newKey);
  };
  useEffect(() => {
    if (selectedToolSpec !== undefined) {
      const newAnalysisTableGroupName = selectedToolSpec.name;
      // TODO: Add suffix back in after prototype is complete
      // let suffix = 0;
      // analysisTableGroups.forEach((group) => {
      //   if (group.toolId === selectedToolSpec.toolId) suffix++;
      // });
      // if (suffix !== 0) {
      //   newAnalysisTableGroupName = `${newAnalysisTableGroupName} (${suffix})`;
      // }
      setTableName(newAnalysisTableGroupName);
    } else {
      setTableName("");
    }
  }, [selectedToolSpec, selectedToolSpecKey]);

  if (selectedToolSpecKey === undefined) {
    return null;
  }

  if (selectedToolSpec === undefined) {
    return null;
  }
  const latestToolVersion = selectedToolSpec.version;
  const toolkit = selectedToolSpec.toolkit;
  const computeCredits = selectedToolSpec.credits;
  const toolVersionDate = moment(selectedToolSpec.versionCreated).format(
    "YYYY-MM-DD",
  );
  const toolDate = moment(selectedToolSpec.toolCreated).format("YYYY-MM-DD");
  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem css={styles.detailList}>
        <EuiDescriptionList
          textStyle="reverse"
          compressed
          listItems={[
            {
              title: "Latest Version",
              description: latestToolVersion,
            },
            {
              title: "Date Latest Version Published",
              description: toolVersionDate,
            },
            {
              title: "Date First Published",
              description: toolDate,
            },
            {
              title: "Toolkit",
              description: <EuiBadge color={"hollow"}>{toolkit}</EuiBadge>,
            },
            {
              title: "Compute Credits",
              description: `${
                isNonNull(computeCredits) ? computeCredits : "N/A"
              } compute credits/hour`,
            },
          ]}
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false} css={styles.tableForm}>
        <EuiFormRow label="Analysis table name">
          <EuiTextArea
            value={tableName}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeName(e)}
            resize={"none"}
            compressed
          />
        </EuiFormRow>
        <EuiFormRow label="Analysis table key">
          <EuiTextArea
            value={tableKey}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChangeKey(e)}
            resize={"none"}
            compressed
          />
        </EuiFormRow>
        <EuiButton
          fill
          fullWidth
          onClick={(e: MouseEvent<HTMLButtonElement>) =>
            handleSubmit(e, tableName, tableKey)
          }
          isLoading={isLoading}
          disabled={tableName.trim() === "" || tableKey.trim() === ""}
        >
          Create Table
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
