/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CellValue, IdeasFile } from "@inscopix/ideas-hyperformula";
import { ModalToolRoiFrameParamWrapper } from "components/ToolParamsGrid/ModalToolRoiFrameParam/ModalToolRoiFrameParamWrapper";
import { ModalToolRoiFrameParamWithFile } from "components/ToolParamsGrid/ModalToolRoiFrameParam/ModalToolRoiFrameParamWithFile";
import { CallOutError } from "components/CallOutError/CallOutError";
import { FILE_FORMATS_BY_KEY } from "types/FileFormats";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { useDataTableContext } from "../store/DataTableProvider";
import { useState } from "react";
import { EuiText, EuiTreeView, EuiTreeViewProps } from "@inscopix/ideas-eui";

interface CellEditorRoiFrameProps {
  initialValue: CellValue;
  rowId: string;
  columnName: string;
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "roi_frame" }>;
  onChange: (newFormula: string | null) => void;
  stopEditing: () => void;
}

/** Cell editor for cells in ROI frame columns */
export const CellEditorRoiFrame = ({
  initialValue,
  rowId,
  columnName,
  columnDefinition,
  onChange,
  stopEditing,
}: CellEditorRoiFrameProps) => {
  const sourceFiles = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === s.selectedTableId);
    const row = table?.rows.find(({ id }) => id === rowId);
    const sourceColumnIndex = table?.columns.findIndex(
      ({ id }) => id === columnDefinition.file_column,
    );

    if (row === undefined || sourceColumnIndex === undefined) {
      return [];
    }

    const cell = row.cells[sourceColumnIndex];
    return cell.value instanceof IdeasFile ? [cell.value] : [];
  });
  const [selectedSourceFileId, setSelectedSourceFileId] = useState(() => {
    return sourceFiles.length === 1 ? sourceFiles[0].attrs.id : undefined;
  });

  //   /**
  //    * None of the selected files could be found (i.e. all have been deleted after they were selected)
  //    */
  //   const isFileNotFound =
  //     sourceFiles.length === 0 &&
  //     projectFilesFromFileIds.fileIdsNotFound.length > 0;

  //   if (isFileNotFound) {
  //     return (
  //       <ModalToolRoiFrameParamWrapper
  //         columnName={columnName}
  //         onClose={stopEditing}
  //       >
  //         <EuiCallOut>
  //           <EuiText>
  //             <p>File not found.</p>
  //             <p>
  //               The selected file could not be located. It may have been deleted.{" "}
  //             </p>
  //           </EuiText>
  //         </EuiCallOut>
  //       </ModalToolRoiFrameParamWrapper>
  //     );
  //   }

  //   /**
  //    * No file selected
  //    */
  //   if (sourceFiles.length === 0 && sourceColumn !== undefined) {
  //     return (
  //       <ModalToolRoiFrameParamNoSourceFile
  //         columnName={columnName}
  //         rowId={rowId}
  //         sourceFileParam={sourceColumn}
  //         onClose={stopEditing}
  //         readOnly={false}
  //       />
  //     );
  //   }

  const onAccept = (shapes: ShapeJson[]) => {
    const newParamValue = JSON.stringify(shapes).replace(/"/g, '\\"');
    onChange(`=ROI_FRAME("${newParamValue}")`);
    setTimeout(stopEditing, 100);
  };

  /**
   * Multiple valid files, allow user to select one
   */

  if (selectedSourceFileId === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper
        columnName={columnName}
        onClose={stopEditing}
      >
        <>
          <EuiText>
            Select which input file you would like to use in the ROI editor. For
            series input, a single child of the series must be selected.
          </EuiText>
          <br />
          <EuiTreeView
            style={{
              border: "1px solid lightgrey",
              borderRadius: 5,
              padding: 10,
            }}
            css={css`
              .euiTreeView__nodeLabel {
                width: 100%;
              }
            `}
            aria-label="File tree"
            // display="compressed"
            showExpansionArrows
            expandByDefault
            items={sourceFiles.map((file) => {
              const treeItem: EuiTreeViewProps["items"][number] = {
                id: file.attrs.id,
                label: (
                  <EuiText
                    onClick={() => setSelectedSourceFileId(file.attrs.id)}
                    size="s"
                  >
                    {file.attrs.name}
                  </EuiText>
                ),
              };
              return treeItem;
            })}
          />
        </>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  const previewFormat =
    FILE_FORMATS_BY_KEY[columnDefinition.source_file_data.file_format]?.id;

  if (previewFormat === undefined) {
    return (
      <ModalToolRoiFrameParamWrapper
        columnName={columnName}
        onClose={stopEditing}
      >
        <CallOutError>Improper tool configuration.</CallOutError>
      </ModalToolRoiFrameParamWrapper>
    );
  }

  return (
    <ModalToolRoiFrameParamWrapper
      columnName={columnName}
      onClose={stopEditing}
      readOnly={false}
      fullScreen
    >
      <ModalToolRoiFrameParamWithFile
        columnDefinition={columnDefinition}
        onCancel={stopEditing}
        fileId={sourceFiles[0].attrs.id}
        fileHeight={200}
        fileWidth={200}
        previewType={{
          fileFormat: previewFormat,
          key: columnDefinition.source_file_data.key,
        }}
        onAccept={onAccept}
        readOnly={false}
        initialShapes={[]}
      />
    </ModalToolRoiFrameParamWrapper>
  );
};
