/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ICellEditorParams } from "ag-grid-community";
import { DataTableRowData } from "../layout/DataTable";
import { CellEditorBoolean } from "./CellEditorBoolean";
import { useDataTableContext } from "../store/DataTableProvider";
import { CellEditorFormula } from "./CellEditorFormula";
import { CellEditorIdentifier } from "./CellEditorIdentifier";
import { CellEditorFloat } from "./CellEditorFloat";
import { CellEditorText } from "./CellEditorText";
import { CellEditorInteger } from "./CellEditorInteger";
import { CellEditorFile } from "./CellEditorFile";
import { CellEditorChoice } from "./CellEditorChoice";
import { CellEditorRoiFrame } from "./CellEditorRoiFrame";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding: 0px 10px;
    border: 1px solid #07c;
  `,
};

interface CellEditorGenericProps extends ICellEditorParams<DataTableRowData> {
  tableId: string;
  columnId: string;
  index: number;
  tableKind: "data" | "analysis";
}

/**
 * Cell editor for cells in formula columns
 */
export const CellEditorBase = forwardRef(function CellEditorBase(
  {
    data,
    index,
    columnId,
    stopEditing,
    tableId,
    tableKind,
  }: CellEditorGenericProps,
  ref,
) {
  const isAdvancedMode = useDataTableContext((s) => s.isAdvancedMode);
  const column = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    return table?.columns.find((column) => column.id === columnId);
  });
  const [newFormula, setNewFormula] = useState(data.cells[index].formula);

  useImperativeHandle(ref, () => ({
    getValue: () => newFormula,
  }));

  if (isAdvancedMode) {
    return (
      <span css={styles.root}>
        <CellEditorFormula
          initialFormula={newFormula}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "boolean") {
    return (
      <span css={styles.root}>
        <CellEditorBoolean
          tableId={tableId}
          columnId={columnId}
          rowId={data.id}
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "choice") {
    return (
      <span css={styles.root}>
        <CellEditorChoice
          tableId={tableId}
          columnId={columnId}
          rowId={data.id}
          columnDefinition={column.definition}
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "file" && tableKind === "analysis") {
    return (
      <span css={styles.root}>
        <CellEditorFile
          tableId={tableId}
          columnId={columnId}
          rowId={data.id}
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "float") {
    return (
      <span css={styles.root}>
        <CellEditorFloat
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "identifier") {
    return (
      <span css={styles.root}>
        <CellEditorIdentifier
          tableId={tableId}
          columnId={columnId}
          rowId={data.id}
          filterTableId={column.definition.table}
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "integer") {
    return (
      <span css={styles.root}>
        <CellEditorInteger
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "roi_frame") {
    return (
      <span css={styles.root}>
        <CellEditorRoiFrame
          rowId={data.id}
          initialValue={data.cells[index].value}
          columnName={column.name}
          columnDefinition={column.definition}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  if (column?.definition.kind === "text") {
    return (
      <span css={styles.root}>
        <CellEditorText
          initialValue={data.cells[index].value}
          onChange={setNewFormula}
          stopEditing={stopEditing}
        />
      </span>
    );
  }

  return null;
});
