import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface FieldTableProps {
  autoFocus?: boolean;
  label?: string;
  value: { id: string; key: string } | undefined;
  onChange: (field: {
    value: { id: string; key: string } | undefined;
    isValid: boolean;
  }) => void;
}

export const FieldTable = ({
  autoFocus,
  label = "Table",
  value,
  onChange,
}: FieldTableProps) => {
  const tables = useDataTableContext((s) => s.tables);

  const options: EuiSuperSelectOption<string>[] = tables.map((table) => ({
    value: table.id,
    inputDisplay: (
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiIcon
            type={
              table.kind === "analysis" ? "indexMapping" : "tableDensityNormal"
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <span>
            {table.name} <span style={{ color: "grey" }}>({table.key})</span>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
  }));

  const handleChange = (tableId: string) => {
    const table = tables.find((table) => table.id === tableId);
    if (table === undefined || table?.id === value?.id) {
      onChange({
        value: undefined,
        isValid: false,
      });
    } else {
      onChange({
        value: { id: table.id, key: table.key },
        isValid: true,
      });
    }
  };

  return (
    <EuiFormRow label={label}>
      <EuiSuperSelect
        autoFocus={autoFocus}
        options={options}
        valueOfSelected={value?.id}
        onChange={handleChange}
        placeholder="Select a table..."
      />
    </EuiFormRow>
  );
};
